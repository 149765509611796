import { UserAction } from '@bendingspoons/pico-core';

/** UserAction fired when the initial login page is displayed. */
export const loginEmailDisplayedAction = (): UserAction => ({
  kind: 'login_email_displayed',
  info: {},
});

/** UserAction fired when the continue button in the login page is pressed and the user is
 * redirected to the password page
 * */
export const loginEmailPressedAction = (): UserAction => ({
  kind: 'login_email_pressed',
  info: {},
});

/** UserAction fired when the continue with Google button in the login page is pressed and the user is
 * redirected to the password page
 * */
export const loginGooglePressedAction = (): UserAction => ({
  kind: 'login_google_pressed',
  info: {},
});

/** UserAction fired when the continue with Apple button in the login page is pressed and the user is
 * redirected to the password page
 * */
export const loginApplePressedAction = (): UserAction => ({
  kind: 'login_apple_pressed',
  info: {},
});

/** UserAction fired when the initial registration page is displayed. */
export const registrationEmailDisplayedAction = (): UserAction => ({
  kind: 'registration_email_displayed',
  info: {},
});

/** UserAction fired when the continue button in the registration page is pressed and the user is
 * redirected to the password page
 * */
export const registrationEmailPressedAction = (): UserAction => ({
  kind: 'registration_email_pressed',
  info: {},
});

/** UserAction fired when the continue with Google button in the registration page is pressed and the user is
 * redirected to the password page
 * */
export const registrationGooglePressedAction = (): UserAction => ({
  kind: 'registration_google_pressed',
  info: {},
});

/** UserAction fired when the continue with Apple button in the registration page is pressed and the user is
 * redirected to the password page
 * */
export const registrationApplePressedAction = (): UserAction => ({
  kind: 'registration_apple_pressed',
  info: {},
});

/** UserAction fired when the login with password page is displayed. */
export const loginWithPasswordDisplayedAction = (): UserAction => ({
  kind: 'login_with_password_email_displayed',
  info: {},
});

/** UserAction fired when the backend returns success in the login with password page and
 * the callback is triggered
 * */
export const loginFrontendCompletedAction = (): UserAction => ({
  kind: 'login_frontend_completed',
  info: {},
});

/** UserAction fired when the initial registration with password page is displayed. */
export const registrationWithPasswordDisplayedAction = (): UserAction => ({
  kind: 'registration_with_password_email_displayed',
  info: {},
});

/** UserAction fired when the backend returns success in the registration with password page and
 * the callback is triggered
 * */
export const registrationFrontendCompletedAction = (): UserAction => ({
  kind: 'registration_frontend_completed',
  info: {},
});

/** UserAction fired when the login with code page is displayed. */
export const loginWithCodeDisplayedAction = (): UserAction => ({
  kind: 'login_with_code_displayed',
  info: {},
});

/** UserAction fired when the backend returns success in the login with code page and
 * the callback is triggered
 * */
export const loginWithCodeCompletedAction = (): UserAction => ({
  kind: 'login_with_code_completed',
  info: {},
});

/** UserAction fired when the initial registration with code page is displayed. */
export const registrationWithCodeDisplayedAction = (): UserAction => ({
  kind: 'registration_with_code_displayed',
  info: {},
});

/** UserAction fired when the backend returns success in the registration with code page and
 * the callback is triggered
 * */
export const registrationWithCodeCompletedAction = (): UserAction => ({
  kind: 'registration_with_code_completed',
  info: {},
});

/** UserAction fired when the teams-sign-up page is displayed
 * */
export const teamsSignUpDisplayedAction = (): UserAction => ({
  kind: 'teams_sign_up_form_displayed',
  info: {},
});

/** UserAction fired when the email inputted in the teams-sign-up page
 * belongs to an existing account
 * */
export const teamsSignUpAccountAlreadyExistsAction = (
  email: string
): UserAction => ({
  kind: 'teams_sign_up_account_already_exists',
  info: {
    email: email,
  },
});

/** UserAction fired when the password inputted in the teams-sign-up page
 * returns an error
 * */
export const teamsSignUpUnsafePasswordAction = (): UserAction => ({
  kind: 'team_sign_up_unsafe_password',
  info: {},
});

/** UserAction fired when the form in the teams-sign-up is successfully
 * sent to the backend and the response is received
 * */
export const teamsSignUpSubmitAction = (
  email: string,
  responseStatus: string
): UserAction => ({
  kind: 'teams_sign_up_form_submit',
  info: {
    email: email,
    response_status: responseStatus,
  },
});

/**
 * Click on “Add Member” button in the Teams sign up invites page
 */
export const teamsSignUpAddMemberClickedAction = (): UserAction => ({
  kind: 'teams_sign_up_add_member_click',
  info: {},
});

/**
 * Click on “Not now, thanks” button in the Teams sign up invites page
 */
export const teamsSignUpSkipInviteMembersClickedAction = (): UserAction => ({
  kind: 'teams_sign_up_skip_invite_users',
  info: {},
});

/** UserAction fired when the form in the teams-sign-up invites page is successfully
 * sent to the backend and the response is received
 */
export const teamsSignUpInviteMembersConfirmAction = (
  email: string,
  responseStatus: string
): UserAction => ({
  kind: 'teams_sign_up_invite_members_confirm_complete',
  info: {
    email: email,
    response_status: responseStatus,
  },
});

export const adminConsoleSidebarLinkClickedAction = (
  pageName: string,
  userId: number
): UserAction => ({
  kind: 'admin_console_sidebar_link_clicked',
  info: {
    page_visited: pageName,
    user_id: userId,
  },
});

export const settingsSidebarLinkClickedAction = (
  pageName: string,
  userId: number
): UserAction => ({
  kind: 'settings_sidebar_link_clicked',
  info: {
    page_visited: pageName,
    user_id: userId,
  },
});

export const adminConsolePageViewedAction = (
  pageName: string,
  billingEmail: string | undefined | null
): UserAction => ({
  kind: 'admin_console_page_viewed',
  info: {
    page_visited: pageName,
    billing_email: billingEmail ?? 'unknown',
  },
});

export const settingsPageViewedAction = (
  pageName: string,
  userId: number | undefined
): UserAction => ({
  kind: 'settings_page_viewed',
  info: {
    page_visited: pageName,
    userId: userId ?? 'unknown',
  },
});
